export const desginMenus = [
    // {
    //     title: '设计管理', key: 'desgin',icon:'icon iconfont icon-design', compTypes: [2], children: [
    //         { title: '设计版权销售', key: '/desgin/sjbq',icon:'icon iconfont icon-OvalPathmengban' },
    //         { title: '分成合作销售', key: '/desgin/fchz',icon:'icon iconfont icon-eee2' },
    //         // { title: '商品分类', key: '/desgin/txgl',icon:'icon iconfont icon-yixuantaoxi' },

    //     ]
    // },
    // {
    //     title: '合同管理', key: 'desgin_ht',icon:'icon iconfont icon-hetongguanli-', compTypes: [2], children: [
    //         { title: '销售合同模板', key: '/desgin/htmb',icon:'icon iconfont icon-icon-test2' }
    //     ]
    // },
    {
        title: '设计管理', key: 'desgin_mgr',icon:'icon iconfont icon-design',compTypes:[2], children: [
            { title: '设计作品库', key: '/desgin/stored',icon:'icon iconfont icon-OvalPathmengban' },
            { title: '分成合作', key: '/desgin/fchz',icon:'icon iconfont icon-eee2' },
        ]
    },
    {
        title: '订单管理', key: 'desgin_or_mgr',icon:'icon iconfont icon-zuimeishejizuopin',compTypes:[2], children: [
            { title: '设计版权', key: '/desgin/order/designBq',icon:'icon iconfont icon-OvalPathmengban' },
            // { title: '分成合作', key: '/desgin/fchz',icon:'icon iconfont icon-eee2' },
            { title: '需求定制', key: '/desgin/order/demandCustomize',icon:'icon iconfont icon-OvalPathmengban' },
        ]
    },
];
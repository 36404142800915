import store from '@/store';
import _ from 'lodash'
import { compMenus } from '@/views/comp/menus'
import { factoryMenus } from '@/views/factory/menus'
import { desginMenus } from '@/views/desgin/menus'
import { saleMenus } from '@/views/sale/menus'
import { agentMenus } from '@/views/agent/menus'
import { wantMenus } from '@/views/want/menus'
import { contractMenus } from '@/views/contract/menus'
import { mineMenus } from '@/views/mine/menus'
import { dayangMenus } from '@/views/dayang/menus'
import { approveMenus } from '@/views/approve/menus'
import { apsMenus } from '@/views/aps/menus'
const navs = [
    {
        name: '我的企业', key: '/mycomp', compTypes: [1, 2, 3, 4, 5, 6, 7, 8], menus: [
            ...compMenus
        ],
    }, {
        name: '工作台', key: '/center', compTypes: [1, 2, 3, 4, 5, 9901, 9902, 9903], menus: [
            ...factoryMenus,
            ...desginMenus,
            ...saleMenus,
            ...agentMenus,
            ...wantMenus,
            ...contractMenus,
            ...mineMenus,
            ...dayangMenus,
            ...approveMenus,
            ...apsMenus,
            {
                title: '政府管理', key: 'gov', icon: 'icon iconfont', compTypes: [99], children: [
                    { title: '企业申请政策管理', icon: 'icon iconfont', key: 'center21' },
                    { title: '家具贷', key: 'center23', icon: 'icon iconfont' },
                    { title: '设计费用', key: 'center24', icon: 'icon iconfont' }
                ]
            },
        ]
    },
    {
        name: '工作台', key: '/supplier', compTypes: [6]
    },
    // {
    //     name: '工作台', key: '/logistic', compTypes: [7]
    // },
    {
        name: '云智造', key: '/mes', compTypes: [1, 5]
    },
    {
        name: '云物流', key: '/cloudLogistic', compTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    },
    {
        name: '云报表', key: '/cloudReportForm', compTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    },
    // {
    //     name: '云打印', key: '/cloudPrint', compTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    // },
    //{
    //    name: '云金融', key: '/cloudFinancial', compTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9]
    //},
    {
        name: '产品集市', key: '/sale/mall/home', icon: 'icon iconfont', compTypes: [3]
    },
    {
        name: '家具工厂', key: '/sale/furniture', compTypes: [3]
    },
    {
        name: '设计超市', key: '/factory/market', compTypes: [1]
    },
    {
        name: '设计公司展厅', key: '/sale/designView', compTypes: [1, 3]
    },
    {
        name: '木材超市', key: '/moodMarket', compTypes: [1]
    },
    {
        name: '零部件超市', key: '/partsMarket', compTypes: [1]
    }
];
function _filter(item) {
    let curCompType = store.state.loginUser.comp.comp_type;
    let privids = _.get(store.state, 'loginUser.user.privids', []);
    let pass = true;
    if (item.compTypes && item.compTypes.indexOf(curCompType) < 0) pass = false;
    if (item.privi && privids.indexOf(item.privi) < 0) pass = false;
    return pass;
}
function getNavs() {
    let curUser = store.state.loginUser;
    if (!(curUser && curUser.comp && curUser.user)) return [];
    return navs.reduce((pre, curNav) => {
        if (_filter(curNav)) {
            let _curNav = _.cloneDeep(curNav);
            if (_curNav.menus) _curNav.menus = _curNav.menus.reduce((pre1, curMenu) => {
                if (_filter(curMenu)) {
                    if (curMenu.children) curMenu.children = curMenu.children.reduce((pre2, curItem) => {
                        if (_filter(curItem)) {
                            pre2.push(curItem);
                        }
                        return pre2;
                    }, []);
                    if (!curMenu.children || (curMenu.children && curMenu.children.length != 0)) {
                        pre1.push(curMenu);
                    }
                }
                return pre1;
            }, []);
            pre.push(_curNav);
        }
        return pre;
    }, []);

}
export { getNavs };
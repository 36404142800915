export const factoryMenus = [
    // {
    //     title: '设计作品', key: 'fac_design_zp',icon:'icon iconfont icon-zuimeishejizuopin',compTypes:[1], children: [
    //         { title: '设计超市', key: '/factory/gmsj',icon:'icon iconfont icon-chaoshi' },
    //     ]
    // },
    { 
        title: '商品管理', key: 'fac_goods_mgr',icon:'icon iconfont icon-design' ,compTypes:[1], children: [
            { title: '版权购买', key: '/factory/designBq',icon:'icon iconfont icon-shangpin-1' },
            { title: '合作商品', key: '/factory/hzzp',icon:'icon iconfont icon-biaodan' },
            { title: '自有商品', key: '/factory/zysp',icon:'icon iconfont icon-design' },
            { title: '商品分类', key: '/factory/txgl',icon:'icon iconfont icon-yixuantaoxi' },
        ]
    },
    {
        title: '订单管理', key: 'fac_dd_mgr',icon:'icon iconfont icon-zuimeishejizuopin',compTypes:[1], children: [
            { title: '销售订单', key: '/factory/xsdd',icon:'icon iconfont icon-qianyue-sjcs' },
            { title: '分销订单', key: '/factory/agent',icon:'icon iconfont icon-qianyue-sjcs' },
            { title: '需求订单', key: '/factory/demandOrder',icon:'icon iconfont icon-qianyue-sjcs' },
        ]
    },
    {
        title: '销售手册', key: 'fact_sale_sc',icon:'icon iconfont icon-xiaoshou',compTypes:[1], children: [
            { title: '产品画册', key: '/factory/xssc',icon:'icon iconfont icon-Fillsjcs',privi:1101 },
            // { title: '销售合同', key: '/factory/xsht',icon:'icon iconfont icon-yigou1' },
        ]
    },
    {
        title: '政策申请', key: 'zfzcsq',icon:'icon iconfont icon-icon_zhengfu',compTypes:[1],children: [
            { title: '产品送审/验收', key: '/factory/gov/cxghyssyssq',icon:'icon iconfont icon-zu8' },
            { title: '金融产品申请', key: '/factory/gov/jrcpsq',icon:'icon iconfont icon-jiajudai' },
            { title: '租金减免申请', key: '/factory/gov/zjjmsq',icon:'icon iconfont icon-jine1' },
            { title: '企业退规申请', key: '/factory/gov/qytgsq',icon:'icon iconfont icon-bianzu13' },
            { title: '企业入规申请', key: '/factory/gov/qyrg',icon:'icon iconfont icon-qianyue-sjcs' },
            { title: '设计奖补申请', key: '/factory/gov/designAllowance',icon:'icon iconfont icon-qianyue-sjcs' }
        ]
    },
];
import { salePurchaseMenus } from '@/views/sale/pur/menus'
export const saleMenus = [
    {
        title: '采购管理', key: 'sale_pur_mgr', icon: 'icon iconfont icon-caigoudingdan', compTypes: [3], children: salePurchaseMenus
    },
    {
        title: '订单管理', key: 'sale_mag', icon: 'icon iconfont icon-zuimeishejizuopin', compTypes: [3], children: [
            { title: '需求订单', key: '/factory/demandOrder', icon: 'icon iconfont icon-qianyue-sjcs' }
        ]
    },
    // {
    //     title: '店铺管理',key: '/sale/shop',icon:'icon iconfont icon-dianpuguanli2',compTypes:[3],privi:1005, children: [
    //         { title: '店铺列表', key: '/sale/shop/list',icon:'icon iconfont icon-dianpuguanli1' },
    //         { title: '上架总览', key: '/sale/shop/putaway/list',icon:'icon iconfont icon-r', },
    //         // { title: '店铺信息', key: '/sale_shop_info' },
    //         // { title: '待发布商品', key: '/sale/shop/item/Sxj',icon:'icon iconfont icon-tubiao_fabushangpin' },
    //         // { title: '商品管理', key: '/sale/shop/item',icon:'icon iconfont icon-shangpin-' },
    //         // { title: '订单管理', key: '/sale/shop/order',icon:'icon iconfont icon-dingdan' },
    //     ]
    // },
];